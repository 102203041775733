import { createSelector } from 'reselect';
import { useDispatch, useSelector } from "react-redux";

export const selectCombinedLayers = createSelector(
  (state) => state.rasterLayers,
  (state) => state.lineworkList.data,
  (state) => state.flightSelected.id,
  (state) => state.userEmail,
  (state) => state.seeList.panoImages,
  (rasterLayers, linework, selectFlightId, userEmail, panoImages) => {
    let unsortedLayers = {};
    const layers = rasterLayers?.combinedLayers && rasterLayers?.combinedLayers || rasterLayers;
if(!rasterLayers?.combinedLayers) {

   // Group all pano layers into a single entry
      // const panoLayers = panoImages?.map((pano) => ({
      //   ...pano,
      //   type: 'panorama',
      //   layout: {
      //     visibility: 'visible',
      //   },
      // }));

      // if (panoLayers.length > 0) {
      //   unsortedLayers['panorama_layers'] = {
      //     title: 'Panorama Layers',
      //     type: 'panoramaGroup',
      //     layers: panoLayers,
      //   };
      // }


    Object.keys(layers).forEach((layerKey) => {
      if (rasterLayers?.[layerKey]?.showInLayerSwitcher === true && layerKey?.includes(selectFlightId)) {
        unsortedLayers[layerKey] = { ...rasterLayers[layerKey], type: 'rasterLayer' };
      }
    });

    linework?.forEach((lw, index) => {
      const baseId = 'linework';
      unsortedLayers[`linework_${index}`] = {
        ...lw,
        type: 'linework',
        layout: {
          lineVisibility: window.map?.getLayoutProperty(`${baseId}-line-${lw.uuid}`, 'visibility') || 'none',
          pointVisibility: window.map?.getLayoutProperty(`${baseId}-point-${lw.uuid}`, 'visibility') || 'none',
          polygonVisibility: window.map?.getLayoutProperty(`${baseId}-polygon-${lw.uuid}`, 'visibility') || 'none',
        },
      };
    });

    if (userEmail === 'lce@hillplain.com') {
      const wmsVisibility = window.map?.getLayer('parcel-fabric-wms-layer') ? 'visible' : 'false';
      unsortedLayers['wms_layer'] = {
        title: 'View Layers',
        type: 'wmsLayer',
        layout: {
          visibility: wmsVisibility || 'none',
      // const wmsLayerIds = ['parcel-fabric-wms-layer', 'admin-boundaries-wms-layer', 'storm_lines'];

        },
      };
    }

    let layersArray = [];
    for (const layer in unsortedLayers) {
      layersArray.push({ name: layer, data: unsortedLayers[layer] });
    }
// return layersArray.sort((a, b) => (b.data.order || 0) - (a.data.order || 0));    

return layersArray;
}
else {
    return rasterLayers?.combinedLayers;
}
    
  }
);