import {createSlice} from "@reduxjs/toolkit";

const rasterLayersSlice = createSlice({
  name: "rasterLayersState",
  initialState: {},
  reducers: {
    createRasterLayerAction() {
    },
    rearrangeRasterLayersAction() {
    },
    setRasterLayerData(state, action) {
      return {
        ...state,
        [action.payload.layerKey]: {...action.payload.layerData}
      }
    },
    exchangeRasterLayerOrderAction(state, action) {
      return {
        ...state,
        [action.payload.sourceLayer]: {...state[action.payload.sourceLayer], order: state[action.payload.destinationLayer].order},
        [action.payload.destinationLayer]: {...state[action.payload.destinationLayer], order: state[action.payload.sourceLayer].order}
      }
    },
    updateRasterLayerTransparencyAction(state, action) {
      return {
        ...state,
        [action.payload.key]: {...state[action.payload.key], paint: {"raster-opacity": action.payload.opacity}}
      }
    },
    updateRasterLayerVisibilityAction(state, action) {
      return {
        ...state,
        [action.payload.key]: {...state[action.payload.key], layout: {visibility: action.payload.visibility}}
      }
    },
   updateLayersOrderAction: (state, action) => {
    const updatedLayers = action.payload;

    updatedLayers.forEach((layer) => {
      if (layer?.data?.type === 'rasterLayer') {
        if (state.rasterLayers && state.rasterLayers?.[layer?.name]) {
          state.rasterLayers[layer.name].order = layer.data.order;
        }
      } else if (layer?.data?.type === 'linework') {
        if (state.lineworkList?.data) {
          const index = state.lineworkList.data.findIndex((l) => l.uuid === layer?.data?.uuid);
          if (index !== -1) {
            state.lineworkList.data[index].order = layer?.data?.order;
          }
        }
      } else if (layer?.data?.type === 'wmsLayer') {
        if (state.wmsLayers && state.wmsLayers?.[layer?.name]) {
          state.wmsLayers[layer.name].order = layer.data.order;
        }
      } else {
        console.warn(`Layer not found or type mismatch for: ${layer?.name || layer?.data?.uuid}`);
      }
    });
    state.combinedLayers = updatedLayers; //.sort((a, b) => (b.data.order || 0) - (a.data.order || 0));
  },
   resetCombinedLayers: (state) => {
      state.combinedLayers = [];
    },

      }
    })

export const {
  createRasterLayerAction,
  setRasterLayerData,
  rearrangeRasterLayersAction,
  exchangeRasterLayerOrderAction,
  updateRasterLayerTransparencyAction,
  updateRasterLayerVisibilityAction,
  updateLayersOrderAction,
  resetCombinedLayers 
} = rasterLayersSlice.actions

export default rasterLayersSlice.reducer